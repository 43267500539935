import React from "react";
import QrCodeGenerator from "./QrCodeGenerator";
import { Base } from "../../widgets/Base/Base";
import { compose, withStateHandlers } from "recompose";
import {
  getLoginInformation,
  getSessionInformation,
  getLoginErrors,
  getResponseErrors,
  getValidLoginError,
} from "../../selectors/authentication";
import * as authenticationActions from "../../actions/authentication";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Background1 from "../../utils/styles/images/showroom-background-2.jpg";
import Background2 from "../../utils/styles/images/showroom-background-3.jpg";
import AfterLogin from "./AfterLogin";
import DialogContent from "@material-ui/core/DialogContent";

const Component = ({
  onClose,
  isOpen,
  actions,
  loginInformation,
  sessionInformation,
}) => {
  console.log("SigninWithQrCode loginInformation", loginInformation);
  console.log("SigninWithQrCode sessionInformation", sessionInformation);
  const subTitle1 = "vous invite à\nvous identifier";
  const subTitle2 = "vous invite à générer\nvotre code d'accès";

  return (
    <Base
      title="Votre esprit d'analyse"
      subTitle={!loginInformation ? subTitle1 : subTitle2}
      backgroundImage={!loginInformation ? Background1 : Background2}
    >
      {!sessionInformation ? (
        <QrCodeGenerator
          actions={actions}
          open={isOpen}
          onClose={onClose}
          sessionInformation={sessionInformation}
          loginInformation={loginInformation}
        />
      ) : !loginInformation ? (
        <div>Loading...</div>
      ) : (
        <DialogContent>
          <AfterLogin
            actions={actions}
            loginInformation={loginInformation}
            sessionInformation={sessionInformation}
          />
        </DialogContent>
      )}
    </Base>
  );
};

const actions = {
  validateLogin: authenticationActions.validateLogin,
  getSessionDetails: authenticationActions.getSessionDetails,
  getChallenge: authenticationActions.getChallenge,
  checkChallenge: authenticationActions.checkChallenge,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = createStructuredSelector({
  loginInformation: getLoginInformation,
  sessionInformation: getSessionInformation,
  error: getLoginErrors,
  responseError: getResponseErrors,
  validLoginError: getValidLoginError,
});

const withDialog = withStateHandlers(() => ({ isOpen: true }), {
  onClose: () => (value) => ({ isOpen: !value }),
});

export const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withDialog
);

export default enhance(Component);
