import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import { compose, lifecycle } from 'recompose';
import { withRouter } from 'react-router-dom';
import { FormattedHTMLMessage } from 'react-intl';
import AccountBlocked from '../../../../widgets/AccountBlocked';
import Login from '../../../../widgets/Login';
import ConventionPending from '../../../../widgets/ConventionPending';


const Component = ({ error, actions, onClose }) => {
    console.log("Component error:", error);

    const getComponent = (response) => {
        const CONVENTION_BLOCKED = 'hia.convention.blocked';
        const CONVENTION_PENDING = 'hia.convention.pending';
        const CONVENTION_NOT_FOUND='hia.account.not.found';
        if (response === CONVENTION_BLOCKED) {
            return <Grid item xs={12}><AccountBlocked error={response}  style={{ fontWeight:"700" }}/></Grid>
        }
        if (response === CONVENTION_PENDING||response===CONVENTION_NOT_FOUND) {
            return <Grid item xs={12}><ConventionPending onClose={onClose} /></Grid>
        }
        else {
            return <Grid item xs={12}><p className='text_login'>{"Enter your username"}</p><Login error={response} actions={actions} /></Grid>
        }
    }

    return (
        <Grid container justify="center">
            <Grid item xs={12}>
                <DialogContent>
                    <Grid item xs={12}>
                        <Grid container justify="center">
                            {error &&
                                <Typography variant="body2" style={{ color: "#28558A", textAlign:"center", marginBottom: "20px"}}>
                                    <FormattedHTMLMessage id={error} />
                                </Typography>
                            }
                            &nbsp;
                        </Grid>
                    </Grid>
                </DialogContent>
            </Grid>
            
                {getComponent(error)}
            
        </Grid>
    )
}



const loadComponents = lifecycle({
    componentDidMount() {
        
    },
    componentWillReceiveProps(nextProps) {
        const { onClose } = this.props;
        if (nextProps.isLoggedIn === true) {
            onClose(true);  
            window.location = '/admin';

        }
    }
},
);

export const enhance = compose(
    loadComponents,
    withRouter,
)
export default enhance(Component);